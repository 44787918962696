import React from 'react'
import styled from 'styled-components';

import Layout from '../components/Layout/Layout';
import Footer from '../components/Footer/Footer';

import {
  GrandHeading,
  Heading,
} from '../styles/typography';

const AboutPage = styled.div`
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 60px 0 0 0;
  background: #FFFFFF;
  box-shadow: inset 0 10px 14px 0 rgba(0,0,0,0.10);
  position: relative;
  @media (max-width: 768px) {
    height: auto;
    padding: 40px 16px 0 16px;
  }
`;

const About = () => (
  <Layout>
    <AboutPage>
      <GrandHeading>
        About
      </GrandHeading>
      <Heading>
        Web-Pal
      </Heading>
      <Heading>
        175204, Russia, Novgorod region, Staraya Russa city, Alexandrovskaya st. - 28, apt. 3
      </Heading>
      <Heading>
        +79267972292
      </Heading>
      <Heading>
        it@web-pal.com
      </Heading>
      <Heading>
        https://web-pal.com
      </Heading>
    </AboutPage>
    <Footer showModal={false} />
  </Layout>
);

export default About;
